<template>
  <div class="mobile-toolbar">
    <router-link to="/" class="toolbar-menu">
      <fa-icon :icon="['fal', 'tachometer-alt']" />
    </router-link>
    <router-link to="/appointments" class="toolbar-menu">
      <fa-icon :icon="['fal', 'calendar-alt']" />
    </router-link>
    <router-link to="/attendances" class="toolbar-menu"
                 :class="{ active: isActive('/medical-reports') }">
      <fa-icon :icon="['fal', 'stethoscope']" />
    </router-link>
    <router-link to="/patients" class="toolbar-menu"
                 :class="{ active: isActive('/patients') }">
      <fa-icon :icon="['fal', 'user-friends']" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isSetBranch: 'isSetBranch',
    }),
  },
  methods: {
    isActive(basePath) {
      return this.$route.path.startsWith(basePath);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import "./src/assets/scss/_colors.scss";

.mobile-toolbar {
  background-color: $secondary-color;
  border-bottom: $border-width solid $border-color;
  color: $light-color;
  display: flex;
  height: $mobile-toolbar-height;
  z-index: $zindex-1;
  .toolbar-menu {
    align-items: center;
    color: $gray-color;
    display: flex;
    flex: 1;
    justify-content: center;
    transition: .2s ease all;
    svg {
      font-size: 1.3rem;
    }
    &.active, &.router-link-exact-active {
      color: $light-color;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
